import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { List, ListItemButton, ListItemText } from '@mui/material';

const LeftNavBar = () => {
    const location = useLocation();

    // This function checks if the path is active
    const isActive = (path) => location.pathname === path;

    return (
        <List component="nav" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItemButton to="/venues" selected={isActive('/venues')}>
                <ListItemText primary="Venues" />
            </ListItemButton>
            <ListItemButton to="/inquiries" selected={isActive('/inquiries')}>
                <ListItemText primary="Inquiries" />
            </ListItemButton>
            <ListItemButton component={Link} to="/bookings" selected={isActive('/bookings')}>
                <ListItemText primary="Bookings" />
            </ListItemButton>
        </List>
    );
};

export default LeftNavBar;
