import {Typography} from "@mui/material";
import React from "react";
import VenuesGrid from "./VenuesGrid";


function VenuesPage() {
  return (
      <div className="App-content">
          <Typography variant="h4" gutterBottom>
              Venues
          </Typography>
          <VenuesGrid/>
      </div>
  );
}

export default VenuesPage;
