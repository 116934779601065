import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchVenues } from "../../api/apiService";

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
        field: 'image_url',
        headerName: 'Photo',
        width: 75,
        renderCell: (params) => (
            <img src={params.value} alt="Venue" style={{ width: 'auto', height: '50px', padding: '4px' }} />
        ),
    },
    { field: 'name', headerName: 'Name', width: 240 },
    { field: 'cityName', headerName: 'City', width: 130 },
    { field: 'state', headerName: 'State', width: 130 },
    { field: 'countryCode', headerName: 'Country Code', width: 130 },

];

function VenuesGrid() {
    const [venues, setVenues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [sortModel, setSortModel] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [filterModel, setFilterModel] = useState({
        items: [],
    });
    const [page, setPage] = useState(0); // page index, starting from 0
    const [pageSize, setPageSize] = useState(0);

    const handlePageChange = (params) => {
        setPage(params.page);
        setPageSize(params.pageSize)
    };


    const handleFilterModelChange = (newModel) => {
        setFilterModel(newModel);
    };

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    };

    useEffect(() => {
        const getVenues = async () => {
            setLoading(true);
            try {
                // Construct the sorting part of the query
                const sortField = sortModel[0]?.field;
                const sortOrder = sortModel[0]?.sort;
                const filterParams = filterModel.items
                    .map(filter => `${filter.field}_${filter.operator}=${encodeURIComponent(filter.value)}`)
                    .join('&');

                // Use these to construct your API endpoint accordingly
                const data = await fetchVenues(page, pageSize, sortField, sortOrder, filterParams);
                setVenues(data.venues);
                setRowCount(data.total);
            } catch (err) {
                setError(err.message || 'Unexpected Error!');
            } finally {
                setLoading(false);
            }
        };

        getVenues();
    }, [sortModel, filterModel, pageSize, page]); // Re-fetch data when these dependencies change

    // Map the data to the DataGrid format
    const rows = venues.map(venue => ({
        id: venue.id,
        name: venue.name,
        cityName: venue.city_name,
        state: venue.state_name,
        countryCode: venue.country_code,
        image_url: venue.image_url,
    }));

    return (
        <div className="App-content" style={{ marginLeft: 0, padding: 0}}>
            {error && <p style={{ color: 'red' }}>Error: {error}</p>}
            <DataGrid
                rows={rows}
                columns={columns}
                sortingMode="server"
                autoPageSize={true}
                sortModel={sortModel}
                filterMode="server"
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
                checkboxSelection
                loading={loading}
                onSortModelChange={handleSortModelChange}
                page={page}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCount}
                paginationMode="server" // Enable server-side pagination
            />
        </div>
    );
}

export default VenuesGrid;
