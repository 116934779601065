import axios from 'axios';
const AUTH_TOKEN_NAME = 'bizly_auth_token'; // TODO: pull from environment variable

if(process.env.REACT_APP_ENV === 'local') {
    axios.defaults.headers.common['bizly-api-client-id'] = process.env.REACT_APP_BIZLY_API_CLIENT_ID;
    axios.defaults.headers.common['bizly-api-client-secret'] = process.env.REACT_APP_BIZLY_API_CLIENT_SECRET;
}
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(AUTH_TOKEN_NAME)}`;

const apiBaseURL = process.env.REACT_APP_BIZLY_API_URL + '/os';

export const fetchVenues = async (page, pageSize, sortField,sortOrder,filterParams) => {
    try {
        const response = await axios.get(`${apiBaseURL}/venues?page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&${filterParams}`);
        return response.data; // The data property contains the actual response data
    } catch (error) {
        throw error;
    }
};


