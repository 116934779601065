import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { Typography } from "@mui/material";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import Login from "./components/Login";
import LeftNavBar from "./components/LeftNavBar";
import VenuesPage from "./features/Venues/VenuesPage";

const Inquiries = () => <div>Inquiries Page</div>;
const Bookings = () => <div>Bookings Page</div>;



function AuthenticatedApp() {
  return (
      <>
        <aside className="App-sidebar">
          <Typography variant="h5" component="h1" className="App-title">
            BizlyOS
          </Typography>
          <LeftNavBar/>
        </aside>
        <main>
          <Routes>
            <Route path="/venues" element={<VenuesPage />} />
            <Route path="/inquiries" element={<Inquiries />} />
            <Route path="/bookings" element={<Bookings />} />
            {/* You can add more protected routes here */}
          </Routes>
        </main>
      </>
  );
}

function AppWrapper() {
  const { currentUser } = useContext(AuthContext);

  return (
      <div className="App">
        {currentUser ? (
            <AuthenticatedApp />
        ) : (
            <Routes>
              <Route path="*" element={<Login />} />
            </Routes>
        )}
      </div>
  );
}

function App() {
  return (
      <AuthProvider>
        <Router>
          <AppWrapper />
        </Router>
      </AuthProvider>
  );
}

export default App;
