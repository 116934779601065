import axios from 'axios';

// Only used in local development, netlify uses netlify.toml to set headers
if(process.env.REACT_APP_ENV === 'local') {
    axios.defaults.headers.common['bizly-api-client-id'] = process.env.REACT_APP_BIZLY_API_CLIENT_ID;
    axios.defaults.headers.common['bizly-api-client-secret'] = process.env.REACT_APP_BIZLY_API_CLIENT_SECRET;
}

const API_URL = process.env.REACT_APP_BIZLY_API_URL;
const AUTH_TOKEN_NAME = 'bizly_auth_token'; // TODO: pull from environment variable

export const authService = {
    login: (email, password) => {
        return axios
            .post(`${API_URL}/authenticate`, { email, password })
            .then((response) => {
                if (response.data.user.auth_token) {
                    localStorage.setItem(AUTH_TOKEN_NAME, response.data.user.auth_token);
                }
                return response.data;
            });
    },
    logout: () => {
        localStorage.removeItem(AUTH_TOKEN_NAME);
    },
    getCurrentUser: () => {
        const token = localStorage.getItem(AUTH_TOKEN_NAME);
        if (!token) return null;

        return token;
    }
};
